<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" :clipped="true" :absolute="false" app style="border-right: 1px solid">
      <v-list dense>
        <v-list-item-group v-model="menu">
          <v-list-item link :to="{ name: 'categories' }">
            <v-list-item-action>
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>CATEGORIAS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'employees' }">
            <v-list-item-action>
              <v-icon>mdi-account-hard-hat</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>FUNCIONÁRIOS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'benefits' }">
            <v-list-item-action>
              <v-icon>mdi-handshake</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>BENEFÍCIOS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>  

          <v-divider></v-divider>

          <v-list-item link :to="{ name: 'profile' }">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>PERFIL</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'users' }">
            <v-list-item-action>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>USUÁRIOS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>          

          <v-divider></v-divider>

           <v-list-item >
            <v-list-item-action>
              <v-icon>mdi-help-circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>AJUDA</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'logout' }">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>SAIR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="#3D1159" dark :clipped-left="true" app :absolute="true">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-img
        class="mx-1"
        src="@/assets/lyndus_logo.png"
        max-height="50"
        max-width="200"
        contain
      ></v-img>

      <v-toolbar-title class="black--text"> </v-toolbar-title>

      <v-spacer></v-spacer>

      <span>
        <v-icon class="mr-2">mdi-account</v-icon>
        {{ currentUser }}
      </span>

      <!-- <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark text v-bind="attrs" v-on="on">
                <v-icon class="mr-2">mdi-account</v-icon>
                {{ currentUser }}
              </v-btn>
            </template>

        <v-list>
          <v-list-item :key="currentUser" link :to="{ name: 'logout' }">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>

    <v-main>
      <v-container fill-height fluid>
        <v-layout>
          <router-view></router-view>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
.v-system-bar a {
  font-family: Arial, Helvetica, sans-serif;
  display: block;
  font-size: 10px;
  color: #82d0f5;
  margin: 0px 0px 10px;
  text-decoration: none;
  line-height: 10px;

  float: left;
  display: block;
  height: 8px;
  margin-top: 13px;
  margin-right: 8px;
  padding-right: 8px;
  border-right: 1px solid #0078b4;
}

.v-system-bar .last {
  border-right: none;
}

.v-system-bar .on {
  color: #ffffff;
}

.v-footer a {
  font-family: "SourceSansPro";
  font-size: 12px;
  color: #ffffff;
  line-height: 15px;
  text-decoration: none;
}
</style>

<script>
import { mapGetters } from "vuex";
// import router from "@/router";
// import store from "@/store";
// import { EMPLOYEES_RESET } from "@/store/mutations/employees.type";
// import { EMPLOYEES_GET } from "@/store/actions/employees.type";
// import store from "@/store";
// import { latLng } from "leaflet";

// import { CHART_CITIES_GET, CHART_DATA_GET } from "@/store/actions.type";
// import { RESET_CHART } from "@/store/mutations.type";

export default {
  name: "App",
  props: {
    source: String
  },

  // async beforeRouteUpdate(to, from, next) {
  //   // Reset state if user goes from /editor/:id to /editor
  //   // The component is not recreated so we use to hook to reset the state.
  //   await store.commit(EMPLOYEES_RESET);
  //   return next();
  // },
  // async beforeRouteEnter(to, from, next) {
  //   Promise.all([
  //     store.commit(EMPLOYEES_RESET),
  //     store.dispatch(EMPLOYEES_GET)
  //   ]).then(() => {
  //     next();
  //   });
  // },

  components: {},

  data: () => ({
    isLoading: false,
    drawer: true,
    menu: 4
  }),

  watch: {},

  methods: {
    getCurrentUser() {
      this.$store.state.user;
    }
  },
  mounted() {
    if (!this.isAuthenticated) {
      // router.push({ name: "login" });
    }
  },
  computed: {
    ...mapGetters(["userData", "currentUser", "isAuthenticated"])
  }
};
</script>
